<template>
  <div>
    <form @submit.prevent="onSubmit">
      <div
        v-show="needInputEmail"
        class="input-with-icon have-icon-left mb-1 flex w-full items-center border-b border-gray-500 py-2 lg:mb-3"
      >
        <span class="icon icon-left mr-2 flex items-center text-lg">
          <IcOutlineEmail class="text-red-dark" />
        </span>
        <input
          v-model="pendingForm.email"
          name="email"
          autocomplete="email"
          class="input flex flex-auto border-none text-sm lg:text-base"
          :placeholder="t('email')"
          type="text"
          :disabled="loading"
        />
      </div>
      <div
        v-for="error of v$.email.$errors"
        :key="error.$uid"
        class="text-sm text-red-dark"
      >
        {{ error.$message }}
      </div>

      <div
        v-show="needInputPhone"
        class="input-with-icon have-icon-left mb-1 mt-2 flex w-full items-center border-b border-gray-500 py-2 lg:mb-3"
      >
        <span class="icon icon-left mr-2 flex items-center text-lg">
          <IcBaselineLocalPhone class="text-red-dark" />
        </span>
        <input
          id="phone-input"
          v-model="pendingForm.phone"
          name="phone"
          autocomplete="phone"
          class="input flex w-full flex-auto border-none text-sm lg:text-base"
          :placeholder="t('phone')"
          type="text"
          :disabled="loading"
        />
      </div>
      <div
        v-for="error of v$.phone.$errors"
        :key="error.$uid"
        class="text-sm text-red-dark"
      >
        {{ error.$message }}
      </div>

      <div
        class="input-with-icon have-icon-left mb-1 mt-2 flex w-full items-center border-b border-gray-500 py-2 lg:mb-3"
      >
        <span class="icon icon-left mr-2 flex items-center text-lg">
          <IcOutlineCake class="text-red-dark" />
        </span>
        <ImaskInput
          v-model="pendingForm.birthDay"
          name="birthDay"
          autocomplete="birthDay"
          mask="MM-DD"
          :blocks="birthDayMaskedInput"
          :lazy="false"
          :overwrite="true"
          class="input flex flex-auto border-none text-sm lg:text-base"
          :placeholder="t('birthDay')"
          type="text"
          :disabled="loading"
        />
      </div>
      <div
        v-for="error of v$.birthDay.$errors"
        :key="error.$uid"
        class="text-sm text-red-dark"
      >
        {{ error.$message }}
      </div>
      <HhButton
        id="login-submit-button"
        type="submit"
        class="mt-2 h-10 w-full"
        rounded
        :disabled="loading || v$.$invalid"
        :loading="loading"
      >
        <div class="font-bold uppercase">
          {{ t("submit") }}
        </div>
      </HhButton>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { IMaskComponent, IMask } from "vue-imask";
import { defineComponent, reactive, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, email, numeric } from "@vuelidate/validators";
import IcOutlineEmail from "~icons/ic/outline-email";
import IcBaselineLocalPhone from "~icons/ic/baseline-local-phone";
import IcOutlineCake from "~icons/ic/outline-cake";
import HhButton from "~/components/HhButton.vue";
import { useIntlTelInput } from "~/lib/intlTelInput";

import alert from "~/lib/alert";
import type { SigInPendingPayload } from "~/types/User";
const intlTelInputLib = await useIntlTelInput();

const ImaskInput = defineComponent(IMaskComponent);

const { t } = useI18n();

interface Props {
  loading?: boolean;
  needInputEmail?: boolean;
  needInputPhone?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  needInputEmail: false,
  needInputPhone: false,
});

const pendingForm = reactive({
  email: "",
  phone: "",
  phoneCode: "",
  birthDay: "",
});

const pendingFormRules = computed(() => {
  const startWithZero = helpers.withMessage(
    "Number must start with 0",
    (value: string) => {
      return value.startsWith("0");
    }
  );
  const birthDayFormat = helpers.withMessage(
    "Birth day must be in MM-DD format",
    (value: string) => {
      const regex = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
      return regex.test(value);
    }
  );
  return {
    email: props.needInputEmail ? { required, email } : {},
    phone: props.needInputPhone ? { required, numeric, startWithZero } : {},
    birthDay: { birthDayFormat },
  };
});

const v$ = useVuelidate(pendingFormRules, pendingForm, { $autoDirty: true });

const birthDayMaskedInput = computed(() => {
  return {
    DD: {
      mask: IMask.MaskedRange,
      placeholderChar: "D",
      from: 1,
      to: 31,
      maxLength: 2,
    },
    MM: {
      mask: IMask.MaskedRange,
      placeholderChar: "M",
      from: 1,
      to: 12,
      maxLength: 2,
    },
  };
});

onMounted(() => {
  initInputTelephone();
});

const initInputTelephone = () => {
  try {
    const el = document.getElementById("phone-input") as HTMLInputElement;
    const advInputTel = intlTelInputLib(el, {
      initialCountry: "th",
      separateDialCode: false,
    });
    pendingForm.phoneCode = `+${advInputTel.getSelectedCountryData().dialCode}`;
    el.style.cssText = "padding-left: 45px";
    el.addEventListener(
      "countrychange",
      () => {
        pendingForm.phoneCode = `+${
          advInputTel.getSelectedCountryData().dialCode
        }`;
      },
      { passive: true }
    );
  } catch (err: any) {
    alert.error(err);
  }
};

const emit = defineEmits<{
  (event: "submit", payload: SigInPendingPayload): void;
}>();

const onSubmit = () => {
  emit("submit", pendingForm);
};
</script>

<style>
.iti.iti--allow-dropdown {
  width: 100%;
}
</style>

<i18n lang="json">
{
  "en": {
    "phone": "Phone",
    "email": "Email",
    "birthDay": "Birth Day",
    "submit": "Submit"
  },
  "th": {
    "phone": "Phone",
    "email": "Email",
    "birthDay": "Birth Day",
    "submit": "Submit"
  }
}
</i18n>
