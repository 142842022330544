<template>
  <HhModal id="signin-modal" v-model="showLoginModal">
    <div class="flex">
      <div class="shrink p-6 sm:p-12" style="width: 500px">
        <img
          src="~/assets/image/logo-new-full-alternate.png"
          width="80"
          loading="lazy"
          alt="Hungry hub Logo"
          class="mx-auto"
        />
        <h1 class="mb-4 mt-8 text-center text-xl font-bold capitalize">
          {{
            needInputPhone
              ? t("whatIsYourPhone")
              : needInputEmail
              ? t("whatIsYourEmail")
              : t("signIn")
          }}
        </h1>
        <LoginForm
          v-if="!isLoginPendingForm"
          :loading-sign-in-email="isLoadingSignInEmail"
          :loading-sign-in-facebook="isLoadingSignInFacebook"
          :loading-sign-in-google="isLoadingSignInGoogle"
          @sign-in-with-facebook="signInWithFacebook"
          @sign-in-with-google="signInWithGoogle"
          @sign-in-with-email="signInWithEmailAndPassword"
          @forgot-password="onForgotPassword"
          @register="onRegister"
        />
        <LoginPendingForm
          v-else
          :loading="isLoadingSignInPending"
          :need-input-email="needInputEmail"
          :need-input-phone="needInputPhone"
          @submit="completePendingSignIn"
        />
      </div>
    </div>
  </HhModal>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useModal } from "~/lib/modal";
import HhModal from "~/components/HhModal.vue";
import LoginForm from "~/components/common/LoginForm.vue";
import LoginPendingForm from "~/components/common/LoginPendingForm.vue";
import useUserStore from "~/stores/user";
import alert from "~/lib/alert";
import useClientTypeStore from "~/stores/clientType";
import { useRoute, useRouter } from "#imports";
import type { EmailPassword, SigInPendingPayload } from "~/types/User";

const clientTypeStore = useClientTypeStore();
const { isDesktop, isMobile } = storeToRefs(clientTypeStore);

const { t } = useI18n();
const userStore = useUserStore();
const { showLoginModal, needInputEmail, needInputPhone } =
  storeToRefs(userStore);

const isLoginPendingForm = computed(() => {
  return needInputEmail.value || needInputPhone.value;
});

const isLoadingSignInEmail = ref(false);
const isLoadingSignInFacebook = ref(false);
const isLoadingSignInGoogle = ref(false);
const isLoadingSignInPending = ref(false);
const hhModal = useModal();

const onRegister = () => {
  hhModal.open("register-modal");
};

const signInWithGoogle = async () => {
  try {
    isLoadingSignInGoogle.value = true;
    const { signInWithSocialMedia } = await import(
      "~/services/auth/signInWithSocialMedia"
    );
    await signInWithSocialMedia({
      provider: "google",
      redirect: !isDesktop.value,
    });
  } catch (err: any) {
    alert.error(err.message);
  } finally {
    isLoadingSignInGoogle.value = false;
  }
};

const signInWithFacebook = async () => {
  try {
    const { signInWithSocialMedia } = await import(
      "~/services/auth/signInWithSocialMedia"
    );
    isLoadingSignInFacebook.value = true;
    await signInWithSocialMedia({
      provider: "facebook",
      redirect: !isDesktop.value,
    });
  } catch (err: any) {
    alert.error(err.message);
  } finally {
    isLoadingSignInFacebook.value = false;
  }
};

const signInWithEmailAndPassword = async (payload: EmailPassword) => {
  try {
    isLoadingSignInEmail.value = true;
    const { signInWithEmail } = await import("~/services/auth/signInWithEmail");
    const { isSuccess, message } = await signInWithEmail(payload);
    if (message) {
      if (isSuccess) {
        alert.success(message);
        return;
      }
      alert.error(message);
    }
  } catch (err: any) {
    alert.error(err.message);
  } finally {
    isLoadingSignInEmail.value = false;
  }
};

const completePendingSignIn = async (payload: SigInPendingPayload) => {
  try {
    const { completeSignInWithSocialMedia } = await import(
      "~/services/auth/signInWithSocialMedia"
    );
    isLoadingSignInPending.value = true;
    await completeSignInWithSocialMedia(payload);
  } catch (err: any) {
    alert.error(err);
  } finally {
    isLoadingSignInPending.value = false;
  }
};

const signInRedirectHandler = async () => {
  if (!isMobile) return;
  const route = useRoute();
  const router = useRouter();
  if (route.query?.redirect) {
    const { handleSignInRedirectResult } = await import(
      "~/services/auth/signInWithSocialMedia"
    );
    await handleSignInRedirectResult();
    const query = { ...route.query };
    delete query.redirect;
    router.push({ query });
  }
};

const onForgotPassword = () => {
  hhModal.open("forgot-password-modal");
};

onMounted(async () => {
  await signInRedirectHandler();
});
</script>

<script lang="ts">
export default {
  name: "LoginModal",
};
</script>

<i18n lang="json">
{
  "en": {
    "signIn": "Sign In",
    "whatIsYourPhone": "What's your phone number ?",
    "whatIsYourEmail": "What's your email ?"
  },
  "th": {
    "signIn": "เข้าสู่ระบบ",
    "whatIsYourPhone": "What's your phone number ?",
    "whatIsYourEmail": "What's your email ?"
  }
}
</i18n>
