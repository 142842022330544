<template>
  <div>
    <div class="mx-auto max-w-xs">
      <HhButton
        id="login-fb-button"
        class="mb-4 h-10 w-full"
        style="background: #1877f2"
        rounded
        :disabled="isSignInDisabled"
        @click="signInWithFacebook"
      >
        <div class="flex items-center py-1">
          <RiFacebookFill class="h-6 w-6" />
          <span class="ml-3 w-full text-center uppercase">{{
            t("signInWithFacebook")
          }}</span>
        </div>
      </HhButton>
      <HhButton
        class="h-10 w-full"
        color="bg-white"
        text-color="text-black"
        rounded
        :disabled="isSignInDisabled"
        @click="signInWithGoogle"
      >
        <div class="flex items-center py-1">
          <LogosGoogleIcon class="h-5 w-5" />
          <span class="ml-3 w-full text-center uppercase">{{
            t("signInWithGoogle")
          }}</span>
        </div>
      </HhButton>
      <div class="py-8 text-center text-sm text-gray-500">
        {{ t("or") }}
      </div>
    </div>

    <form @submit.prevent="signInWithEmail">
      <div
        class="input-with-icon have-icon-left mb-1 flex w-full items-center border-b border-gray-500 py-2 lg:mb-3"
      >
        <span class="icon icon-left mr-2 flex items-center text-lg">
          <IcOutlineEmail class="text-red-dark" />
        </span>
        <input
          v-model="signInForm.email"
          name="email"
          autocomplete="email"
          class="input flex flex-auto border-none text-sm lg:text-base"
          :placeholder="t('email')"
          type="text"
          :disabled="isSignInDisabled"
        />
      </div>
      <div
        v-for="error of v$.email.$errors"
        :key="error.$uid"
        class="text-sm text-red-dark"
      >
        {{ error.$message }}
      </div>

      <div
        class="input-with-icon have-icon-left mb-1 mt-2 flex w-full items-center border-b border-gray-500 py-2 lg:mb-3"
      >
        <span class="icon icon-left mr-2 flex items-center text-lg">
          <IcBaselineLock class="text-red-dark" />
        </span>

        <input
          v-model="signInForm.password"
          name="passowrd"
          autocomplete="current-password"
          class="input flex flex-auto border-none text-sm lg:text-base"
          :placeholder="t('password')"
          :type="showPassword ? 'text' : 'password'"
          :disabled="isSignInDisabled"
        />

        <button
          id="show-password-button"
          type="button"
          class="icon icon-right mr-2 flex items-center text-lg"
          @click="showPassword = !showPassword"
        >
          <MdiEyeOutline v-show="showPassword" />
          <MdiEyeOffOutline v-show="!showPassword" />
        </button>
      </div>
      <div
        v-for="error of v$.password.$errors"
        :key="error.$uid"
        class="text-sm text-red-dark"
      >
        {{ error.$message }}
      </div>

      <HhButton
        id="login-submit-button"
        type="submit"
        class="mt-4 h-10 w-full"
        rounded
        :disabled="isSignInDisabled || v$.$invalid"
        :loading="loadingSignInEmail"
      >
        <div class="font-bold uppercase">
          {{ t("signIn") }}
        </div>
      </HhButton>
    </form>
    <div class="my-3 text-center text-sm">
      {{ t("notAmember") }}
      <a class="font-bold text-red-dark" href="" @click.prevent="onRegister">{{
        t("register")
      }}</a>
    </div>
    <div class="text-center text-sm">
      <a
        class="font-bold text-red-dark"
        href=""
        @click.prevent="onForgotPassword"
        >{{ t("forgotPassword") }}</a
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { reactive, ref, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";
import HhButton from "~/components/HhButton.vue";
import IcOutlineEmail from "~icons/ic/outline-email";
import IcBaselineLock from "~icons/ic/baseline-lock";
import LogosGoogleIcon from "~icons/logos/google-icon";
import RiFacebookFill from "~icons/ri/facebook-fill";
import MdiEyeOutline from "~icons/mdi/eye-outline";
import MdiEyeOffOutline from "~icons/mdi/eye-off-outline";
import useUserStore from "~/stores/user";
import alert from "~/lib/alert";
import { useRoute, useRouter } from "#imports";
import type { EmailPassword } from "~/types/User";

const { t } = useI18n();

interface Props {
  loadingSignInEmail?: boolean;
  loadingSignInFacebook?: boolean;
  loadingSignInGoogle?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  loadingSignInEmail: false,
  loadingSignInFacebook: false,
  loadingSignInGoogle: false,
});

const isSignInDisabled = computed(
  () =>
    props.loadingSignInEmail ||
    props.loadingSignInFacebook ||
    props.loadingSignInGoogle
);

const showPassword = ref(false);
const signInForm = reactive({
  email: "",
  password: "",
});

const signInFormRules = computed(() => {
  return {
    email: { required, email },
    password: { required },
  };
});

const v$ = useVuelidate(signInFormRules, signInForm, { $autoDirty: true });

const emit = defineEmits<{
  (e: "sign-in-with-facebook"): void;
  (e: "sign-in-with-google"): void;
  (e: "sign-in-with-email", value: EmailPassword): void;
  (e: "forgot-password"): void;
  (e: "register"): void;
}>();

const signInWithFacebook = () => {
  emit("sign-in-with-facebook");
};

const signInWithGoogle = () => {
  emit("sign-in-with-google");
};

const signInWithEmail = () => {
  emit("sign-in-with-email", {
    email: signInForm.email,
    password: signInForm.password,
  });
};

const onForgotPassword = () => {
  emit("forgot-password");
};

const onRegister = () => {
  emit("register");
};
</script>

<i18n lang="json">
{
  "en": {
    "signIn": "Sign In",
    "signInWithFacebook": "Sign In With Facebook",
    "signInWithGoogle": "Sign In With Google",
    "or": "OR",
    "loading": "Please wait ...",
    "notAmember": "Not a member ?",
    "register": "Register",
    "forgotPassword": "Forgot password?",
    "email": "Email",
    "password": "Password"
  },
  "th": {
    "signIn": "เข้าสู่ระบบ",
    "signInWIthFacebook": "เข้าสู่ระบบด้วยเฟซบุ๊ก",
    "signInWithGoogle": "เข้าสู่ระบบด้วย Google",
    "or": "หรือ",
    "loading": "โปรดรอสักครู่ ...",
    "notAmember": " ผู้ใช้ใหม่?",
    "register": "ลงทะเบียน",
    "forgotPassword": "ลืมรหัสผ่าน?",
    "email": "อีเมล",
    "password": "พาสเวิร์ด"
  }
}
</i18n>
